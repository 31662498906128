import { template as template_ae9b99d1d7f346b0ad722fd5b021dbd3 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ae9b99d1d7f346b0ad722fd5b021dbd3(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

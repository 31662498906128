import { template as template_de9fd37feb0d4649ab092c010e9d50d8 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_de9fd37feb0d4649ab092c010e9d50d8(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;

import { template as template_0d174c95b3e54aa2a2b2de6eac114715 } from "@ember/template-compiler";
const SidebarSectionMessage = template_0d174c95b3e54aa2a2b2de6eac114715(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

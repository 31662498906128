import { template as template_2c6dbd5b303340f091bbf49d36983452 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_2c6dbd5b303340f091bbf49d36983452(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;

import { template as template_9ecd087e0f274d6a8cca372d3d414700 } from "@ember/template-compiler";
import { on } from "@ember/modifier";
const BulkSelectCell = template_9ecd087e0f274d6a8cca372d3d414700(`
  <td class="bulk-select topic-list-data">
    <label for="bulk-select-{{@topic.id}}">
      <input
        {{on "click" @onBulkSelectToggle}}
        checked={{@isSelected}}
        type="checkbox"
        id="bulk-select-{{@topic.id}}"
        class="bulk-select"
      />
    </label>
  </td>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectCell;
